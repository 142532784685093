import { FontAwesome, Ionicons } from "@expo/vector-icons";
import { useEffect, useState } from "react";
import { View, TouchableOpacity, SafeAreaView, Text } from "react-native";
import Colors from "../constants/Colors";
import ContentHandler, { CalendarEntry } from "../handler/ContentHandler";
import Toast from "./Toast";

let SET_VISIBLE: React.Dispatch<React.SetStateAction<boolean>> | undefined;
let SET_SHOW_TOAST: React.Dispatch<React.SetStateAction<boolean>> | undefined;
let CURRENT_CALENDAR_ENTRY: CalendarEntry | undefined | null;
let CURRENT_TOAST_CONFIG: ToastConfig | undefined;

export interface ToastConfig {
  title: string;
  text: string;
  backgroundColor: string;
  time: number;
}

export default function Root() {
  const [isVisible, setIsVisible] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    SET_VISIBLE = setIsVisible;
    SET_SHOW_TOAST = setShowToast;
  }, []);

  useEffect(() => {
    if (showToast) {
      let t = setInterval(() => {
        clearInterval(t);
        setShowToast(false);
      }, CURRENT_TOAST_CONFIG?.time);
    }
  }, [showToast]);

  return (
    <SafeAreaView
      pointerEvents={isVisible ? "auto" : "none"}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isVisible ? "#00000088" : "transparent",
      }}
    >
      {!showToast ? null : (
        <View
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
          }}
        >
          <Toast config={CURRENT_TOAST_CONFIG} />
        </View>
      )}
      {!isVisible ? null : <InfoModal />}
    </SafeAreaView>
  );
}

const InfoModal = () => {
  let event = CURRENT_CALENDAR_ENTRY;

  const [refresh, setRefresh] = useState(false);

  if (event === null || event === undefined) return null;
  return (
    <View
      style={{
        width: "100%",
        height: 300,
        backgroundColor: "white",
        padding: 20,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          toggleModal(null);
        }}
        style={{
          position: "absolute",
          right: 16,
          top: 12,
        }}
      >
        <Ionicons
          name="close"
          size={24}
          color={Colors.text}
          style={{ marginRight: 0, marginTop: 2 }}
        />
      </TouchableOpacity>
      <Text
        style={{
          fontSize: 17,
          fontWeight: "bold",
          marginRight: 50,
          fontFamily: "MontserratBold",
        }}
      >
        {event.name}
      </Text>
      <Text
        style={{
          fontSize: 15,
          fontStyle: "italic",
          marginTop: 5,
          fontFamily: "MontserratRegular",
        }}
      >
        {event.description}
      </Text>
      <Text
        style={{
          fontSize: 15,
          opacity: 0.5,
          marginTop: 16,
          fontFamily: "MontserratRegular",
        }}
      >
        {event.location}
      </Text>
      <TouchableOpacity
        style={{ position: "absolute", bottom: 20, right: 20 }}
        onPress={() => {
          if (ContentHandler.getInstance().isItemInAgenda(event)) {
            ContentHandler.getInstance().removeItemFromAgenda(event);
          } else {
            ContentHandler.getInstance().addItemToAgenda(event);
          }
          setRefresh((r) => !r);
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text
            style={{
              color: Colors.tint,
              fontFamily: "MontserratBold",
              fontSize: 15,
              marginRight: 5,
            }}
          >
            {ContentHandler.getInstance().isItemInAgenda(event)
              ? "Added"
              : "Add to My Agenda"}
          </Text>
          {ContentHandler.getInstance().isItemInAgenda(event) ? (
            <Ionicons
              name={"checkmark-done-outline"}
              size={26}
              color={Colors.tint}
            />
          ) : (
            <Ionicons name={"add"} size={26} color={Colors.tint} />
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

export function toggleModal(e: CalendarEntry | null) {
  CURRENT_CALENDAR_ENTRY = e;
  if (SET_VISIBLE !== undefined) SET_VISIBLE((s) => !s);
}

export function showToast(config: ToastConfig) {
  CURRENT_TOAST_CONFIG = config;
  if (SET_SHOW_TOAST !== undefined) SET_SHOW_TOAST(true);
}
