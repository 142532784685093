import AsyncStorage from "@react-native-async-storage/async-storage";
import { collection, query, where, getDocs } from "firebase/firestore";
import ContentHandler from "./ContentHandler";
import { db } from "./FirebaseHandler";

interface User {
  adress_country: string;
  adress_zip: string;
  created_at: string;
  email: string;
  files: Files;
  files_uploaded: boolean;
  first_name: string;
  last_email_sent: Lastemailsent;
  last_name: string;
  link_token: string;
  normal_price: string;
  order_date: string;
  order_number: string;
  paid_price: string;
  ticket_number: string;
  ticket_status: string;
  ticket_type: string;
  updated_at: string;
  wishes: Wishes;
}

interface Wishes {
  friday: Friday;
  saturday: Saturday;
}

interface Saturday {
  aboutyou: string;
  bearingpoint: string;
  dte: string;
  metzler: string;
  oddo: string;
}

interface Friday {
  basf: string;
  bnp: string;
  wts: string;
  xtp: string;
}

interface Lastemailsent {
  nanoseconds: number;
  seconds: number;
}

interface Files {
  black_rock_round_table: string;
  created_at: string;
  name: string;
}

export default class UserHandler {
  static _instance: undefined | UserHandler = undefined;
  _user: User | undefined;
  static getInstance() {
    if (this._instance === undefined) this._instance = new UserHandler();
    return this._instance;
  }

  getFirstName() {
    if (this._user === undefined) return "";
    return this._user.first_name;
  }

  getTicketNumber() {
    if (this._user === undefined) return "";
    return this._user.ticket_number;
  }

  getFullName() {
    if (this._user === undefined) return "";
    return this._user.first_name.trim() + " " + this._user.last_name.trim();
  }

  getTicketType() {
    if (this._user === undefined) return "";
    return this._user.ticket_type;
  }

  async getUserDataFromId(id: string) {
    try {
      const ref = collection(db, "participants");
      const q = query(ref, where("ticket_number", "==", id));
      const querySnapshot = await getDocs(q);
      let user: User | undefined;
      querySnapshot.forEach((doc) => {
        user = doc.data();
      });
      if (user === undefined) return false;
      this._user = user;
      AsyncStorage.setItem("id", user.ticket_number);
      ContentHandler.getInstance().getData();
      ContentHandler.getInstance().subscribeToData();
      return true;
    } catch {
      return false;
    }
  }
}
