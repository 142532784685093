import { Notification } from "../screens/NotificationModalScreen";
//import { doc, documentId, onSnapshot, collection } from "firebase/firestore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { addMinutes } from "../screens/TabOneScreen";
import { showErrorMessage, showSuccessMessage } from "./ErrorHandler";
import { db } from "./FirebaseHandler";
import UserHandler from "./UserHandler";

export interface CalendarEntry {
  id: number;
  name: string;
  description: string;
  location: string;
  start_date: string;
  end_date: string;
  start_date_correction?: any;
  end_date_correction?: any;
  location_correction?: any;
  level: number;
  direction: number;
  color: number;
  highlight: number;
  needs_assignment: number;
  num_content?: number;
  position?: number;
  html: string;
}

export default class ContentHandler {
  static _instance: undefined | ContentHandler = undefined;
  _currentDay = "fr";

  _allNews: Notification[] = [];
  _agendaItems: CalendarEntry[] = [];
  _refreshHandlers: React.Dispatch<React.SetStateAction<boolean>>[] = [];
  _pushToken: string = "";
  _setNewsState:
    | React.Dispatch<React.SetStateAction<Notification[]>>
    | undefined;
  _newNotificationRefreshHandler:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;

  _allData: CalendarEntry[] = [];
  _currentData: CalendarEntry[] = [];

  TOKEN = "af37c0df-e05b-46ae-b773-fb02c4b2f4c6";
  _roomTypes = ["Audimax", "S0.01", "CL/EL", "99", "Foyer"];

  async getData() {
    const url = "https://rapunzel.fsbusinessforum.de/api/events/";
    try {
      const response = await fetch(url + "all", {
        method: "GET",
        headers: new Headers({
          Authorization: this.TOKEN,
        }),
      });
      const data = await response.json();
      this._allData = data.data;
      //this.addAmountAndPositionToElements();
      const asyncResponse = await AsyncStorage.getItem("agenda_items");
      if (asyncResponse !== null) this._agendaItems = JSON.parse(asyncResponse);
      const asyncDay = await AsyncStorage.getItem("current_day");
      if (asyncDay !== null) this._currentDay = asyncDay;
      this.setCurrentDay(this._currentDay);
      //this._refreshHandlers.forEach((r) => r((f) => !f));
      return true;
    } catch {
      return false;
    }
  }

  subscribeToData() {
    let interval = setInterval(() => {
      this.getData();
      this._refreshHandlers.forEach((r) => r((f) => !f));
    }, 60000);
  }

  addItemToAgenda(item: CalendarEntry) {
    this._agendaItems.push(item);
    this.updateAgendaStorage();
  }

  removeItemFromAgenda(item: CalendarEntry) {
    this._agendaItems = this._agendaItems.filter((f) => f.id !== item.id);
    this.updateAgendaStorage();
  }

  isItemInAgenda(item: CalendarEntry) {
    let inc = this._agendaItems.filter((f) => f.id === item.id);
    let s = inc.length === 0;
    return !s;
  }

  updateAgendaStorage() {
    this._refreshHandlers.forEach((r) => r((m) => !m));
    AsyncStorage.setItem("agenda_items", JSON.stringify(this._agendaItems));
  }

  static getInstance() {
    if (this._instance === undefined) this._instance = new ContentHandler();
    return this._instance;
  }

  getCurrentDay() {
    return this._currentDay;
  }

  setPushToken(token: string) {
    this._pushToken = token;
  }

  setNewsState(state: React.Dispatch<React.SetStateAction<Notification[]>>) {
    this._setNewsState = state;
    state(this._allNews);
  }

  subscribeToNews() {
    let initialLoading = true;
    const unsub = db.collection("messages").onSnapshot((querySnapshot) => {
      const notifications: Notification[] = [];
      querySnapshot.forEach((doc) => {
        notifications.push(doc.data() as Notification);
      });
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (!initialLoading)
            this.notifyNewNotification(change.doc.data() as Notification);
        }
      });
      if (this._setNewsState !== undefined) this._setNewsState(notifications);
      this._allNews = notifications;
      initialLoading = false;
    });
  }

  async notifyNewNotification(data: Notification) {
    if (this._newNotificationRefreshHandler !== undefined)
      this._newNotificationRefreshHandler(true);

    const message = {
      to: this._pushToken,
      sound: "default",
      title: "NEWS" + (data.urgency === 0 ? " - URGENT" : ""),
      body: data.content,
      data: { someData: "goes here" },
    };

    await fetch("https://exp.host/--/api/v2/push/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Accept-encoding": "gzip, deflate",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    });

    if (data.urgency === 0) {
      showErrorMessage({
        title: "New Notification",
        text: data.content,
      });
    } else {
      showSuccessMessage({
        title: "New Notification",
        text: data.content,
      });
    }
  }

  setNewNotificationsFalse() {
    if (this._newNotificationRefreshHandler !== undefined)
      this._newNotificationRefreshHandler(false);
  }
  getAllAgendaItemsForCurrentDay() {
    if (this._agendaItems === undefined || this._agendaItems.length === 0)
      return [];
    return this._agendaItems.filter((f) => {
      if (f.start_date_correction !== null) {
        return (
          getValidDate(f.start_date_correction).getDate() ===
          (this._currentDay === "fr" ? 18 : 19)
        );
      }
      return (
        getValidDate(f.start_date).getDate() ===
        (this._currentDay === "fr" ? 18 : 19)
      );
    });
  }

  setCurrentDay(day: string) {
    this._currentDay = day;
    AsyncStorage.setItem("current_day", day);
    this._currentData = this._allData.filter((f) => {
      if (f.start_date_correction !== null) {
        return (
          getValidDate(f.start_date_correction).getDate() ===
          (this._currentDay === "fr" ? 18 : 19)
        );
      }
      return (
        getValidDate(f.start_date).getDate() ===
        (this._currentDay === "fr" ? 18 : 19)
      );
    });
    this._refreshHandlers.forEach((e) => e((r) => !r));
  }

  getCurrentEntry(time: string) {
    function getCorrectTimeString(data: CalendarEntry, type: string) {
      if (type === "start") {
        if (data.start_date_correction !== null) {
          return dateToTimeString(data.start_date_correction);
        } else {
          return dateToTimeString(data.start_date);
        }
      } else {
        if (data.end_date_correction !== null) {
          return dateToTimeString(data.end_date_correction);
        } else {
          return dateToTimeString(data.end_date);
        }
      }
    }

    if (this._allData === undefined) return [];
    let items = this._allData.filter((f) => {
      const compareArray = createCompareArray(
        getCorrectTimeString(f, "start"),
        getCorrectTimeString(f, "end")
      );
      return compareArray.includes(time);
    });

    return items;
  }

  addRefresh(set: React.Dispatch<React.SetStateAction<boolean>>) {
    if (!this._refreshHandlers.includes(set)) this._refreshHandlers.push(set);
  }

  removeRefresh(set: React.Dispatch<React.SetStateAction<boolean>>) {
    this._refreshHandlers = this._refreshHandlers.filter((f) => f !== set);
  }
}

export function dateToTimeString(time: string | Date) {
  const date = getValidDate(time);
  let value = pad(date.getHours()) + ":" + pad(date.getMinutes());
  return value;
}

export function isFriday(time: string | Date) {
  const date = getValidDate(time);
  return date.getDay() === 18;
}

export function getValidDate(time: string | Date) {
  if (typeof time == "string") {
    const timestamp = Date.parse(time.replace(/\s/, "T"));
    var date = new Date(timestamp);
  } else {
    var date = time;
  }
  return date;
}

function pad(value: number) {
  if (value < 10) {
    return "0" + value;
  } else {
    return value;
  }
}

function createCompareArray(startTime: string, endTime: string) {
  let ra = [startTime];
  while (ra[ra.length - 1] !== endTime) {
    ra.push(addMinutes(ra[ra.length - 1], "15"));
  }
  return ra;
}
