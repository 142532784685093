/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome, Ionicons } from "@expo/vector-icons";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  useNavigation,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { Pressable, View, Platform } from "react-native";

import Colors from "../constants/Colors";
import ModalScreen from "../screens/ModalScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import TabOneScreen from "../screens/TabOneScreen";
import LaunchScreen from "../screens/LaunchScreen";
import TabTwoScreen from "../screens/TabTwoScreen";
import {
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps,
} from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import NotificationModalScreen from "../screens/NotificationModalScreen";
import LoadingScreen from "../screens/LoadingScreen";
import { DetailScreen } from "../screens/DetailScreen";
import ContentHandler from "../handler/ContentHandler";
import { NotificationsScreen } from "../screens/NotificationsScreen";
import InfoModalScreen from "../screens/InfoScreen";

export default function Navigation() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const navigation = useNavigation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="LoadingScreen"
        component={LoadingScreen}
        options={{ headerShown: false, title: "Loading..." }}
      />
      <Stack.Screen
        name="Root"
        component={TabOneScreen}
        options={{
          title: "Timetable",
          headerTitleStyle: {
            fontFamily: "MontserratBold",
            color: "black",
          },
          headerShown: true,
          headerRight: () => <NavbarIcons navigation={navigation} />,
        }}
      />

      {/* <Stack.Screen
        name="LaunchScreen"
        component={LaunchScreen}
        options={{ headerShown: false }}
      /> */}
      {/* <Stack.Screen
        name="NotificationsScreen"
        component={NotificationsScreen}
        options={{ headerShown: false }}
      /> */}
      <Stack.Screen
        name="DetailScreen"
        component={DetailScreen}
        options={{
          headerTitleStyle: {
            fontFamily: "MontserratBold",
            color: "black",
          },
          headerTintColor: "#000",
          // headerRight: () => <NavbarIcons navigation={navigation} />,
        }}
      />

      <Stack.Screen
        name="Info"
        component={InfoModalScreen}
        options={{
          headerTitleStyle: {
            fontFamily: "MontserratBold",
            color: "black",
          },
          headerTintColor: "#000",
          // headerRight: () => <NavbarIcons navigation={navigation} />,
        }}
      />

      {/* <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="Profile"
          component={ModalScreen}
          options={{
            headerTitleStyle: {
              fontFamily: "MontserratBold",
              color: "white",
            },
            headerTintColor: "#000",
            headerStyle: {
              backgroundColor: Colors.accentBlue,
            },
            headerRight: () => (
              <Pressable
                onPress={() => {
                  navigation.goBack();
                }}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5 : 1,
                })}
              >
                <Ionicons
                  name="close"
                  size={28}
                  color={"white"}
                  style={{
                    ...Platform.select({ web: { marginRight: 20 } }),
                    marginTop: 2,
                  }}
                />
              </Pressable>
            ),
          }}
        />
        <Stack.Screen
          name="Notification"
          component={NotificationModalScreen}
          options={{
            title: "Notifications",
            headerTitleStyle: {
              fontFamily: "MontserratBold",
            },
            headerRight: () => (
              <Pressable
                onPress={() => {
                  navigation.goBack();
                }}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5 : 1,
                })}
              >
                <Ionicons
                  name="close"
                  size={28}
                  color={Colors.text}
                  style={{
                    ...Platform.select({ web: { marginRight: 20 } }),
                    marginTop: 2,
                  }}
                />
              </Pressable>
            ),
          }}
        />
      </Stack.Group> */}
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

// function BottomTabNavigator() {
//   const [newNotification, setNewNotification] = React.useState(false);

//   React.useEffect(() => {
//     ContentHandler.getInstance()._newNotificationRefreshHandler =
//       setNewNotification;
//     ContentHandler.getInstance().subscribeToNews();
//   }, []);

//   return (
//     <BottomTab.Navigator
//       initialRouteName="TabOne"
//       screenOptions={{
//         tabBarActiveTintColor: Colors.tint,
//       }}
//     >
//       <BottomTab.Screen
//         name="TabOne"
//         component={TabOneScreen}
//         options={({ navigation }: RootTabScreenProps<"TabOne">) => ({
//           title: "Timetable",
//           headerTitleStyle: {
//             fontFamily: "MontserratBold",
//           },
//           tabBarIcon: ({ color }) => (
//             <TabBarIcon name="calendar" color={color} />
//           ),
//           headerRight: () => (
//             <NavbarIcons
//               navigation={navigation}
//               newNotification={newNotification}
//             />
//           ),
//         })}
//       />
//       <BottomTab.Screen
//         name="TabTwo"
//         component={TabTwoScreen}
//         options={({ navigation }: RootTabScreenProps<"TabTwo">) => ({
//           title: "My Agenda",
//           headerTitleStyle: {
//             fontFamily: "MontserratBold",
//           },
//           tabBarIcon: ({ color }) => <TabBarIcon name="list" color={color} />,
//           headerRight: () => (
//             <NavbarIcons
//               navigation={navigation}
//               newNotification={newNotification}
//             />
//           ),
//         })}
//       />
//     </BottomTab.Navigator>
//   );
// }

const NavbarIcons = (props: { navigation: any }) => {
  const { navigation } = props;
  return (
    <View style={{ flexDirection: "row" }}>
      <Pressable
        onPress={() => navigation.navigate("Info")}
        style={({ pressed }) => ({
          opacity: pressed ? 0.5 : 1,
        })}
      >
        <Ionicons
          name="information-circle-outline"
          size={28}
          color={Colors.text}
          style={{ marginRight: 20 }}
        />
      </Pressable>
    </View>
  );
};
/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof Ionicons>["name"];
  color: string;
}) {
  return <Ionicons size={24} style={{ marginBottom: -3 }} {...props} />;
}
