import { Text, View } from "react-native";
import UserHandler from "../handler/UserHandler";

interface HeaderComponentProps {
  subtitle: string;
}

export function HeaderComponent(props: HeaderComponentProps) {
  return (
    <View style={{ padding: 16 }}>
      <Text
        style={{
          fontSize: 22,
          fontWeight: "bold",
          fontFamily: "MontserratBold",
        }}
      >
        Timetable
      </Text>
      {/* <Text
        style={{
          fontSize: 17,
          marginTop: 5,
          fontWeight: "300",
          fontFamily: "MontserratLight",
        }}
      >
        {props.subtitle}
      </Text> */}
    </View>
  );
}
