import Colors from "../constants/Colors";
import Toast from "../modal-popup/Toast";

interface ErrorMessageProps {
  title: string;
  text: string;
}

export function showErrorMessage(props: ErrorMessageProps) {
  const { title, text } = props;

  Toast.show({
    title: title,
    text: text,
    color: Colors.tint,
    backgroundColor: Colors.tint,
    timeColor: "#aaa",
    timing: 5000,
    position: "bottom",
  });
}

export function showSuccessMessage(props: ErrorMessageProps) {
  const { title, text } = props;

  Toast.show({
    title: title,
    text: text,
    color: Colors.accentBlue,
    backgroundColor: Colors.accentBlue,
    timeColor: "#aaa",
    timing: 5000,
    position: "bottom",
  });
}
