import { useNavigation } from "@react-navigation/native";
import { Image, ScrollView, StyleSheet, Text, View } from "react-native";
import Colors from "../constants/Colors";

export default function InfoModalScreen() {
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <ScrollView style={{ width: "100%", maxWidth: 1000 }}>
        <Floorplan />
        <ColorsComponent />
      </ScrollView>
    </View>
  );
}

const Floorplan = () => {
  return (
    <View style={{ width: "100%", marginVertical: 20 }}>
      <Image
        style={{
          width: "100%",
          aspectRatio: 0.83,
          resizeMode: "contain",
        }}
        source={{
          uri: "https://firebasestorage.googleapis.com/v0/b/fs-business-foru.appspot.com/o/Floorplan%20-%20Full.png?alt=media&token=c38e4bc2-91e1-4eec-aad7-4c7cc67f4b3f",
        }}
      />
    </View>
  );
};

const ColorsComponent = () => {
  return (
    <View
      style={{
        paddingLeft: 20,
        width: "100%",
        paddingRight: 20,
        marginBottom: 50,
      }}
    >
      <Text
        style={{ fontFamily: "MontserratBold", fontSize: 17, marginBottom: 8 }}
      >
        Colors:
      </Text>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <ColorView title="Speech" color={Colors.entryColors[0]} />
        <ColorView title="Workshop" color={Colors.entryColors[1]} />
      </View>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <ColorView title="Fireside-Chat" color={Colors.entryColors[2]} />
        <ColorView title="Panel" color={Colors.entryColors[3]} />
      </View>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <ColorView title="Food & Networking" color={Colors.entryColors[4]} />
      </View>
    </View>
  );
};

const ColorView = (props: { title: string; color: string }) => {
  const { title, color } = props;
  return (
    <View
      style={{
        alignItems: "center",
        flexDirection: "row",
        marginVertical: 12,
      }}
    >
      <View
        style={{
          width: 40,
          height: 40,
          backgroundColor: color,
          marginRight: 12,
        }}
      ></View>
      <Text
        style={{ fontFamily: "MontserratRegular", fontSize: 15, minWidth: 80 }}
      >
        {title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "white",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
