const tintColorLight = '#ec4d3b';

export default {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    accentBlue: "#31417a",
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    entryColors : {
        "0": "#8eaae1",
        "1": "#70798c",
        "2": "#776b69",
        "3": "#c5b896",
        "4": "#777777",
        "5": "#df7d68"
    }
    
};
