import { Text, View } from "react-native";
import { isIPhoneWithMonobrow } from "react-native-status-bar-height";
import { ToastConfig } from "./Root";

export default function Toast(props: ToastConfig | undefined) {
  if (props === undefined) return null;
  const { title, text, backgroundColor } = props.config;
  return (
    <View
      style={{
        width: "100%",
        padding: 20,
        backgroundColor: backgroundColor,
        paddingBottom: isIPhoneWithMonobrow() ? 50 : 0,
      }}
    >
      <Text style={{ color: "white", fontSize: 17, fontWeight: "bold" }}>
        {title}
      </Text>
      <Text style={{ color: "white", fontSize: 14, marginTop: 8 }}>{text}</Text>
    </View>
  );
}
