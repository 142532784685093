const systemFonts = ["MontserratRegular", "MontserratBold"];
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  Platform,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import RenderHTML from "react-native-render-html";
import WebView from "react-native-webview";
//import RenderHTML from "react-native-render-html";
import Colors from "../constants/Colors";
import ContentHandler, { dateToTimeString } from "../handler/ContentHandler";
import { TimeAndLocationText } from "./TabOneScreen";

export function DetailScreen({ route }) {
  const { item } = route.params;
  const navigation = useNavigation();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    navigation.setOptions({ title: item.name });
  }, []);
  return (
    <View style={{ flex: 1, backgroundColor: "white" }}>
      <View style={{ backgroundColor: Colors.accentBlue, padding: 16 }}>
        <Text
          style={{
            fontFamily: "MontserratBold",
            fontSize: 13,
            color: "white",
            opacity: 0.75,
            marginBottom: 10,
          }}
        >
          {item.name}
        </Text>
        <Text
          style={{
            fontFamily: "MontserratBold",
            fontSize: 17,
            color: "white",
            marginBottom: 10,
          }}
        >
          {item.description}
        </Text>
        <TimeAndLocationText
          item={item}
          textProps={{
            opacity: 0.75,
            marginBottom: 10,
            fontFamily: "MontserratBold",
            fontSize: 13,
            color: "white",
          }}
        />
      </View>
      <TouchableOpacity
        style={{ width: "100%", alignItems: "center", padding: 20 }}
        onPress={() => {
          if (ContentHandler.getInstance().isItemInAgenda(item)) {
            ContentHandler.getInstance().removeItemFromAgenda(item);
          } else {
            ContentHandler.getInstance().addItemToAgenda(item);
          }
          setRefresh((r) => !r);
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text
            style={{
              color: Colors.tint,
              fontFamily: "MontserratBold",
              fontSize: 15,
              marginRight: 5,
            }}
          >
            {ContentHandler.getInstance().isItemInAgenda(item)
              ? "Added"
              : "Add to My Agenda"}
          </Text>
          {ContentHandler.getInstance().isItemInAgenda(item) ? (
            <Ionicons
              name={"checkmark-done-outline"}
              size={26}
              color={Colors.tint}
            />
          ) : (
            <Ionicons name={"add"} size={26} color={Colors.tint} />
          )}
        </View>
      </TouchableOpacity>
      <View
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "black",
          opacity: 0.3,
        }}
      />
      {Platform.OS === "web" ? (
        <div
          style={{ padding: 20, fontFamily: "MontserratRegular", fontSize: 15 }}
          dangerouslySetInnerHTML={{ __html: item.html }}
        ></div>
      ) : // <RenderHTML
      //   source={{ html: item.html }}
      //   contentWidth={useWindowDimensions().width - 40}
      //   systemFonts={systemFonts}
      //   tagsStyles={{
      //     body: { padding: 20, marginTop: -12 },
      //     p: { fontFamily: "MontserratRegular", fontSize: 15 },
      //     strong: { fontFamily: "MontserratBold", fontSize: 15 },
      //   }}
      // />
      null}
    </View>
  );
}
