import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useState } from "react";
import {
  FlatList,
  Platform,
  RecyclerViewBackedScrollViewComponent,
  RefreshControl,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import EditScreenInfo from "../components/EditScreenInfo";
import { HeaderComponent } from "../components/HeaderComponent";
import SelectDayComponent from "../components/SelectDayComponent";
import Colors from "../constants/Colors";
import ContentHandler, {
  CalendarEntry,
  dateToTimeString,
} from "../handler/ContentHandler";
import { toggleModal } from "../modal-popup/Root";

import { RootTabScreenProps } from "../types";

export default function TabOneScreen({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  return (
    <View style={styles.container}>
      <HeaderComponent subtitle={"Timetable"} />
      <SelectDayComponent />
      <View
        style={{
          marginLeft: 50,
          marginTop: 0,
          marginRight: 12,
        }}
      >
        {/* <View style={{ flexDirection: "row", width: "100%", marginBottom: 6 }}>
          {ContentHandler.getInstance()._roomTypes.map((room) => {
            return (
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Text style={{ opacity: 0.5, fontSize: 12 }}>{room}</Text>
              </View>
            );
          })}
        </View> */}
        {/* <View
          style={{
            width: 500,
            height: 1,
            marginLeft: -50,
            backgroundColor: "#000",
            opacity: 0.5,
            marginTop: 2,
          }}
        /> */}
      </View>
      <TimetableAgenda />
    </View>
  );
}

const TimetableComponent = () => {
  const times = createTimes();
  const [height, setHeight] = useState(1);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let timer = setInterval(() => {
      setRefresh((r) => !r);
    }, 10000);

    ContentHandler.getInstance().addRefresh(setRefresh);

    return () => {
      ContentHandler.getInstance().removeRefresh(setRefresh);
      clearInterval(timer);
    };
  }, []);

  return (
    <FlatList
      data={times}
      renderItem={({ item }) => {
        return (
          <View
            onLayout={(event) => {
              setHeight(event.nativeEvent.layout.height);
            }}
            style={{
              width: "100%",
              paddingTop: 20,
              paddingHorizontal: 12,
              overflow: "visible",
            }}
          >
            <ContainerView item={item} height={height} />
            {!hasCurrentTime(addMinutes(item, "-15")) ? null : (
              <View
                style={{
                  width: "110%",
                  height: 1,
                  zIndex: 100,
                  position: "absolute",
                  left: 0,
                  top: getTopDistance(height),
                  backgroundColor: "#8B0000",
                }}
              >
                <View style={styles.triangle}></View>
              </View>
            )}
            <Text
              style={{
                fontSize: 10,
                color: isLeadingTime(item) ? Colors.tint + "88" : "#aaa",
              }}
            >
              {item}
            </Text>
            <View
              style={{
                width: "100%",
                height: 1,
                backgroundColor: isLeadingTime(item)
                  ? Colors.tint + "44"
                  : "#ddd",
                marginTop: 2,
              }}
            />
          </View>
        );
      }}
      style={{ flex: 1, width: "100%" }}
    />
  );
};

const ContainerView = (props: { item: string; height: number }) => {
  let { item, height } = props;
  item = addMinutes(item, "-15");

  const entrys = transformEntrys(item);
  return (
    <View
      key={Math.random()}
      style={{
        flex: 1,
        flexDirection: "row",
        marginLeft: 50,
        zIndex: 10,
        top: 0,
        height: height,
        width: "90%",
        position: "absolute",
        overflow: "visible",
      }}
    >
      {entrys.map((e) => {
        if (e.name === "_") {
          return (
            <View
              key={Math.random()}
              style={{
                flex: 1,
                marginLeft: 1,
                marginRight: 1,
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
            />
          );
        } else {
          return (
            <TouchableWithoutFeedback
              style={{
                zIndex: dateToTimeString(e.start_date) === item ? 100 : 1,
                overflow: "visible",
                ...Platform.select({ web: { cursor: "pointer" } }),
              }}
              key={Math.random()}
              onPress={() => {
                toggleModal(e);
              }}
            >
              <View
                key={Math.random()}
                style={{
                  flex: 1,
                  borderTopLeftRadius:
                    dateToTimeString(e.start_date) === item ? 0 : 0,
                  borderTopRightRadius:
                    dateToTimeString(e.start_date) === item ? 0 : 0,
                  borderBottomLeftRadius:
                    dateToTimeString(e.end_date) === item ? 0 : 0,
                  borderBottomRightRadius:
                    dateToTimeString(e.end_date) === item ? 0 : 0,
                  overflow: "visible",
                  marginTop: dateToTimeString(e.start_date) === item ? 1 : 0,
                  marginLeft: 1,
                  marginRight: 1,
                  marginBottom: dateToTimeString(e.end_date) === item ? 3 : 0,
                  backgroundColor: Colors.entryColors[e.color],
                }}
              >
                {dateToTimeString(e.start_date) === item ? (
                  <Text
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      overflow: "visible",
                      padding: 4,
                      ...Platform.select({
                        web: { fontSize: 12 },
                        android: { fontSize: 9 },
                        ios: { fontSize: 9 },
                      }),
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {e.name}
                  </Text>
                ) : null}
                {dateToTimeString(e.end_date) === item &&
                ContentHandler.getInstance().isItemInAgenda(e) ? (
                  <Ionicons
                    name={"checkmark-done-outline"}
                    size={18}
                    style={{ position: "absolute", right: 6, bottom: 4 }}
                    color={"white"}
                  />
                ) : null}
              </View>
            </TouchableWithoutFeedback>
          );
        }
      })}
    </View>
  );
};

const TimetableAgenda = () => {
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState<CalendarEntry[]>([]);
  const [times, setTimes] = useState<string[]>([]);
  const [reloadData, setReloadData] = useState(false);
  const navigation = useNavigation();

  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    let response = await ContentHandler.getInstance().getData();
    setTimeout(() => setRefreshing(false), 700);
  }, []);

  useEffect(() => {
    ContentHandler.getInstance().addRefresh(setReloadData);

    let timer = setInterval(() => {
      setRefresh((r) => !r);
    }, 10000);

    return () => {
      ContentHandler.getInstance().removeRefresh(setReloadData);
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    let data = ContentHandler.getInstance()._currentData;
    let _times = data.map((e) => {
      if (
        e.start_date_correction !== null &&
        e.start_date_correction !== undefined
      )
        return dateToTimeString(e.start_date_correction);
      return dateToTimeString(e.start_date);
    });
    _times = [...new Set(_times)];
    _times = _times.sort((t1, t2) => (t1 > t2 ? 0 : -1));

    setTimes(_times);
    setData(data);
  }, [reloadData]);

  return (
    <View
      style={{
        flex: 1,
        width: "100%",
        ...Platform.select({ web: { marginTop: -20 } }),
      }}
    >
      <FlatList
        style={{ flex: 1, width: "100%", paddingTop: 20 }}
        contentContainerStyle={{
          paddingBottom: Platform.OS === "web" ? 0 : 20,
        }}
        data={times}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        renderItem={({ item }) => {
          return (
            <View style={{ width: "100%" }}>
              <View
                style={{
                  width: "100%",
                  padding: 12,
                  paddingVertical: 8,
                  backgroundColor: Colors.accentBlue,
                }}
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 12,
                    fontFamily: "MontserratBold",
                  }}
                >
                  {item}
                </Text>
              </View>
              {data
                .filter((f) => {
                  if (f.start_date_correction !== null) {
                    return dateToTimeString(f.start_date_correction) === item;
                  } else {
                    return dateToTimeString(f.start_date) === item;
                  }
                })
                .map((e) => {
                  return (
                    <TouchableOpacity
                      activeOpacity={0.7}
                      style={{ width: "100%" }}
                      onPress={() =>
                        navigation.navigate("DetailScreen", { item: e })
                      }
                    >
                      <View
                        style={{
                          width: "100%",
                          backgroundColor:
                            e.highlight === 0 ? "white" : "#ffd70011",
                        }}
                      >
                        <View style={{ width: "100%", flexDirection: "row" }}>
                          <View
                            style={{
                              width: 32,
                              overflow: "visible",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: Colors.entryColors[e.color],
                            }}
                          >
                            <Text
                              style={{
                                color: "white",
                                fontFamily: "MontserratBold",
                                fontSize: 12,
                                width: 100,
                                textAlign: "center",
                                textTransform: "uppercase",
                                transform: [{ rotate: "90deg" }],
                              }}
                            >
                              {e.location}
                            </Text>
                          </View>
                          <View style={{ flex: 1, padding: 16 }}>
                            <Text
                              style={{
                                fontFamily: "MontserratRegular",
                                fontSize: 12,
                                color: "black",
                                opacity: 0.5,
                                marginBottom: 10,
                              }}
                            >
                              {e.name}
                            </Text>
                            <Text
                              style={{
                                fontFamily: "MontserratBold",
                                fontSize: 15,
                                color: "black",
                                marginBottom: 10,
                              }}
                            >
                              {e.description}
                            </Text>
                            <TimeAndLocationText
                              item={e}
                              textProps={{
                                fontFamily: "MontserratRegular",
                                fontSize: 12,
                                color: "black",
                                opacity: 0.5,
                              }}
                            />
                          </View>
                          <View
                            style={{
                              padding: 12,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Ionicons
                              name="chevron-forward-outline"
                              size={24}
                            />
                          </View>
                        </View>
                        {data
                          .filter(
                            (f) => dateToTimeString(f.start_date) === item
                          )
                          .indexOf(e) !==
                        data.filter(
                          (f) => dateToTimeString(f.start_date) === item
                        ).length -
                          1 ? (
                          <View
                            style={{
                              width: "100%",
                              height: 1,
                              backgroundColor: "#ddd",
                            }}
                          />
                        ) : null}
                      </View>
                    </TouchableOpacity>
                  );
                })}
            </View>
          );
        }}
      ></FlatList>
    </View>
  );
};

function transformEntrys(item: string) {
  let entrys = ContentHandler.getInstance().getCurrentEntry(item);
  let num = 0;
  let dif = 0;

  entrys = entrys.sort((e, e2) => (e.position! > e2.position! ? 0 : -1));

  entrys.forEach((f) => {
    if (f.num_content! > num) num = f.num_content!;
  });
  if (entrys.length !== num) {
    dif = num - entrys.length;
  }
  for (let i = 0; i < num; i++) {
    if (entrys[i]?.position == i) {
      continue;
    }
    entrys.push({ position: i, name: "_" });
    entrys = entrys.sort((e, e2) => (e.position! > e2.position! ? 0 : -1));
  }
  entrys = entrys.sort((e, e2) => (e.position! > e2.position! ? 0 : -1));

  return entrys;
}

function isLeadingTime(time: string) {
  if (time.includes("00")) return true;
  return false;
}

function hasCurrentTime(time: string) {
  const now = new Date();
  const hoursTime = parseInt(time.slice(0, 2));
  const minutesTime = parseInt(time.slice(3, 5));
  if (hoursTime !== now.getHours()) {
    return false;
  }
  if (now.getMinutes() > minutesTime && now.getMinutes() < minutesTime + 15) {
    return true;
  }
  return false;
}

function createTimes() {
  let times = ["09:00"];
  while (times.length < 50) {
    times.push(addMinutes(times[times.length - 1], "15"));
  }
  return times;
}

function getTopDistance(height: number) {
  const minutes = new Date().getMinutes();
  const distance =
    (height / 15) * (minutes - (Math.ceil(minutes / 15) - 1) * 15);
  return distance;
}

export function addMinutes(time: string, minsToAdd: string) {
  function D(J: number) {
    return (J < 10 ? "0" : "") + J;
  }
  var piece: string[] = time.split(":");
  var mins = parseInt(piece[0]) * 60 + +piece[1] + +minsToAdd;

  return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "white",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  triangle: {
    width: 0,
    height: 0,
    borderTopWidth: 6,
    borderRightWidth: 0,
    top: -5.7,
    borderBottomWidth: 6,
    borderLeftWidth: 10,
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "transparent",
    borderLeftColor: "#8B0000",
  },
});

export const TimeAndLocationText = (props: {
  item: CalendarEntry;
  textProps: StyleProp<TextStyle>;
}) => {
  const { item, textProps } = props;
  const start =
    item.start_date_correction !== null
      ? dateToTimeString(item.start_date_correction)
      : dateToTimeString(item.start_date);
  const end =
    item.end_date_correction !== null
      ? dateToTimeString(item.end_date_correction)
      : dateToTimeString(item.end_date);
  const location =
    item.location_correction !== null
      ? item.location_correction
      : item.location;

  const hasCorrection =
    item.start_date_correction !== null ||
    item.end_date_correction !== null ||
    item.location_correction !== null;
  const day =
    ContentHandler.getInstance()._currentDay === "fr" ? "Friday " : "Saturday ";

  return (
    <Text
      style={[
        textProps,
        {
          opacity: hasCorrection ? 1 : StyleSheet.flatten(textProps).opacity,
          color: hasCorrection
            ? Colors.tint
            : StyleSheet.flatten(textProps).color ?? "white",
          fontFamily: hasCorrection
            ? "MontserratBold"
            : StyleSheet.flatten(textProps).fontFamily,
        },
      ]}
    >
      {location + ", " + day + start + " - " + end}
    </Text>
  );
};
