import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { Image, Platform, View } from "react-native";
import Colors from "../constants/Colors";
import UserHandler from "../handler/UserHandler";
import splash from "../assets/images/splash.png";
import ContentHandler from "../handler/ContentHandler";

export default function LoadingScreen() {
  const navigation = useNavigation();

  async function checkUser() {
    let id = await AsyncStorage.getItem("id");
    if (id === null || id === "") {
      navigation.reset({
        index: 0,
        routes: [{ name: "LaunchScreen" }],
      });
      return;
    }
    let success = await UserHandler.getInstance().getUserDataFromId(id);
    if (success) {
      let target = await getNavigationTarget();
      navigation.reset({
        index: 0,
        routes: [{ name: target }],
      });
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: "LaunchScreen" }],
      });
    }
  }

  useEffect(() => {
    (async () => {
      await ContentHandler.getInstance().getData();
      navigation.reset({
        index: 0,
        routes: [{ name: "Root" }],
      });
    })();
  }, []);

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: Colors.accentBlue,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image
        source={splash}
        style={{ width: "100%", height: "100%", maxWidth: 500 }}
      />
    </View>
  );
}

export async function getNavigationTarget() {
  if (Platform.OS === "web") return "Root";
  let has_notification = await AsyncStorage.getItem("has_notification");
  if (has_notification !== null && has_notification === "true") return "Root";

  return "NotificationsScreen";
}
