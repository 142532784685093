import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

let config = {
  apiKey: "AIzaSyA_n8rsyzfuyrtDe0Saw_Lq42YGMbjXs1g",
  authDomain: "fs-business-foru.firebaseapp.com",
  projectId: "fs-business-foru",
  storageBucket: "fs-business-foru.appspot.com",
  messagingSenderId: "678166307316",
  appId: "1:678166307316:web:6a3065542de865dfbe99a1",
  measurementId: "G-E5X3SG8R86",
};

const app = initializeApp(config);
const db = getFirestore(app);

export { db };
