import { useEffect, useState } from "react";
import ContentHandler from "../handler/ContentHandler";
import SegmentedControl from "@react-native-segmented-control/segmented-control";

import { Text, TouchableOpacity, View } from "react-native";
import Colors from "../constants/Colors";

export default function SelectDayComponent() {
  const [tabIndex, setTabIndex] = useState(0);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    let currentDay = ContentHandler.getInstance().getCurrentDay();
    if (currentDay === "fr") setTabIndex(0);
    else setTabIndex(1);
  }, [reload]);

  useEffect(() => {
    if (tabIndex === 0) {
      ContentHandler.getInstance().setCurrentDay("fr");
    } else {
      ContentHandler.getInstance().setCurrentDay("sa");
    }
  }, [tabIndex]);

  useEffect(() => {
    ContentHandler.getInstance().addRefresh(setReload);

    return () => {
      ContentHandler.getInstance().removeRefresh(setReload);
    };
  }, []);

  return (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <TouchableOpacity
        activeOpacity={0.7}
        style={{
          width: "50%",
          height: 50,
        }}
        onPress={() => setTabIndex(0)}
      >
        <View
          style={{
            backgroundColor: tabIndex === 0 ? Colors.accentBlue : "#e1e1e1",
            padding: 8,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: tabIndex === 0 ? "white" : "black",
              fontSize: 12,
              fontFamily: "MontserratBold",
            }}
          >
            Friday
          </Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        activeOpacity={0.7}
        style={{
          width: "50%",
          height: 50,
        }}
        onPress={() => setTabIndex(1)}
      >
        <View
          style={{
            padding: 8,
            backgroundColor: tabIndex !== 0 ? Colors.accentBlue : "#e1e1e1",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              fontFamily: "MontserratBold",
              color: tabIndex !== 0 ? "white" : "black",
            }}
          >
            Saturday
          </Text>
        </View>
      </TouchableOpacity>
      {/* <SegmentedControl
        values={["Friday", "Saturday"]}
        selectedIndex={tabIndex}
        onChange={(event) => {
          setTabIndex(event.nativeEvent.selectedSegmentIndex);
        }}
        fontStyle={{ fontSize: 12 }}
        style={{ height: 28 }}
      /> */}
    </View>
  );
}
